<template>
  <tp-modal name="modal-create-service-area" width="50%" max-width="768px">
    <v-card class="border-0">
      <div class="card px-5 py-3 d-flex" height="56x">
        <div>
          <v-toolbar-title class="font-weight-bold">
            Tạo phiếu dịch vụ
          </v-toolbar-title>
        </div>

        <v-spacer></v-spacer>
        <div class="text-right">
          <p class="my-0">Người tạo: <b>Long Trần</b></p>
          <p class="my-0">Thời gian ghi: <b>10:20 22/02/2021</b></p>
        </div>
      </div>
    </v-card>

    <!--        Start: actions-->
    <div
      class="v-window-item tab-item grey lighten-3 pa-5 v-window-item--active"
    >
      <v-card>
        <!--        Start: body -->
        <div class="card modal-body-scroll">
          <div class="tab-item lighten-3 pa-5">
            <div class="mb-4">
              <!--              <div class="font-weight-bold">Kỹ thuật tiếp nhận</div>-->
              <div>
                <v-row class="d-flex align-center">
                  <v-col cols="6" class="py-0">
                    <v-autocomplete
                      v-model="guaranteeVoucher.reception_technique"
                      class="text-body-1 text-right"
                      hide-selected
                      clearable
                      dense
                      flat
                      hide-details
                      :items="employees"
                      item-text="name"
                      item-value="id"
                      :menu-props="{ maxWidth: '332px' }"
                      no-data-text="Không có dữ liệu"
                      placeholder="Kỹ thuật tiếp nhận"
                      outlined
                      single-line
                    />
                  </v-col>
                  <v-col cols="6" class="py-0">
                    <div class="d-flex">
                      <v-checkbox
                        label="Phần cứng"
                        class="mt-0 mb-0 pt-0"
                        hide-details
                        dense
                        v-model="guaranteeVoucher.require_hardware"
                      ></v-checkbox>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
            <v-row>
              <v-col cols="6" class="mb-5 py-0">
                <div>
                  <!--                  <div class="font-weight-bold mb-2">Nhập serial sản phẩm</div>-->
                  <div class="d-flex align-center">
                    <span
                      v-if="
                        guaranteeVoucher.id ||
                          (outerSerial && outerSerial.customer_id)
                      "
                    ></span>
                    <v-text-field
                      v-else
                      v-model="serialNumber"
                      class="text-body-1"
                      clearable
                      dense
                      hide-details="auto"
                      outlined
                      placeholder="Nhập số serial và ấn enter"
                    ></v-text-field>
                  </div>
                </div>

                <v-btn
                  class="rounded-lg mt-3"
                  color="primary"
                  dark
                  depressed
                  outlined
                  @click="$modal.show({ name: 'modal-outer-serial' })"
                >
                  <v-icon left>mdi-plus</v-icon> Thêm Serial chưa có trên hệ
                  thống
                </v-btn>
                <template v-if="serialInfo && serialInfo.serial_number">
                  <!-- Start: Serial detail -->
                  <v-card class="grey lighten-4 px-5 py-4 mt-4" flat>
                    <div class="mb-1">
                      <span class="font-weight-bold">Serial: </span>
                      <span>{{ serialInfo.serial_number }}</span>
                    </div>
                    <div class="mb-1">
                      <span class="font-weight-bold">Tên sản phẩm: </span>
                      <span>{{ serialInfo.product_info.product_name }}</span>
                    </div>
                    <div class="mb-1">
                      <span class="font-weight-bold">SKU:</span>
                      {{ serialInfo.product_info.option_sku }} -
                      <span
                        class="text-subtitle"
                        v-html="serialInfo.product_info.option_name"
                      ></span>
                    </div>
                    <div class="mb-1">
                      <span class="font-weight-bold">Giá bán:</span>
                      <span>{{
                        serialInfo.product_info.sold_price | formatCurrency
                      }}</span>
                    </div>
                    <div class="mb-1">
                      <span class="font-weight-bold">Gói bảo hành: </span>
                      <span>{{ serialInfo.guarantee_info.name }}</span>
                    </div>
                    <div class="mb-1">
                      <span class="font-weight-bold">Ngày kích hoạt: </span>
                      <span>{{ serialInfo.guarantee_info.trigger_time }}</span>
                    </div>
                    <div class="mb-1">
                      <span class="font-weight-bold">Hạn bảo hành: </span>
                      <span
                        class="font-weight-bold"
                        :class="
                          compareDateWithToday(
                            serialInfo.guarantee_info.due_time
                          )
                            ? 'green--text'
                            : 'red--text'
                        "
                      >
                        {{ serialInfo.guarantee_info.due_time }}
                      </span>
                    </div>
                  </v-card>
                  <!-- End: Serial detail -->
                  <!-- Start: Customer info -->
                  <v-card class="grey lighten-4 px-5 py-4 mt-4" flat>
                    <div class="mb-1">
                      <span class="font-weight-bold">Khách hàng: </span>
                      <span>{{ serialInfo.bought_by.name }}</span>
                    </div>
                    <div
                      class="mb-1"
                      v-if="serialInfo.bought_by && serialInfo.bought_by.phone"
                    >
                      <span class="font-weight-bold">SĐT: </span>
                      <span>{{
                        serialInfo.bought_by.phone | VMask("### ### ####")
                      }}</span>
                    </div>
                    <div>
                      <span class="font-weight-bold">Hóa đơn bán: </span>
                      <span>{{ serialInfo.bought_by.hdb_code }}</span>
                    </div>
                  </v-card>
                  <!-- End: Customer info -->
                </template>
                <template v-if="outerSerial && outerSerial.customer_id">
                  <!-- Start: Serial detail -->
                  <v-card class="grey lighten-4 px-5 py-4 mt-4" flat>
                    <div class="mb-1">
                      <span class="font-weight-bold">Serial: </span>
                      <span>{{ outerSerial.serial_number }}</span>
                    </div>
                    <div class="mb-1">
                      <span class="font-weight-bold">Tên sản phẩm: </span>
                      <span>{{ outerSerial.product_name }}</span>
                    </div>
                  </v-card>
                  <!-- End: Serial detail -->
                  <!-- Start: Customer info -->
                  <v-card class="grey lighten-4 px-5 py-4 mt-4" flat>
                    <div class="mb-1">
                      <span class="font-weight-bold">Khách hàng: </span>
                      <span
                        v-if="outerSerial.customer && outerSerial.customer.name"
                        >{{ outerSerial.customer.name }}</span
                      >
                    </div>
                    <div
                      class="mb-1"
                      v-if="outerSerial.customer && outerSerial.customer.phone"
                    >
                      <span class="font-weight-bold">SĐT: </span>
                      <span>{{
                        outerSerial.customer.phone | VMask("### ### ####")
                      }}</span>
                    </div>
                  </v-card>
                  <!-- End: Customer info -->
                </template>
              </v-col>
            </v-row>
            <div class="">
              <!--              <div class="font-weight-bold mb-2">Mô tả</div>-->
              <v-textarea
                class="text-body-1"
                dense
                hide-details="auto"
                placeholder="Mô tả tình trạng sản phẩm, những yếu tố đã kiểm tra"
                outlined
                v-model="guaranteeVoucher.tinh_trang_may"
              ></v-textarea>
            </div>

            <div>
              <div class="mt-4">
                <div class="d-flex flex-row align-center justify-space-between">
                  <div></div>
                  <v-btn
                    class="primary--text font-weight-bold text-none rounded px-0"
                    depressed
                    text
                    small
                    @click="$refs.file.click()"
                    style="min-width: auto;"
                  >
                    <v-icon class="mr-1" left>mdi-upload-outline</v-icon>
                    <!--                  Tải lên-->
                  </v-btn>
                  <input type="file" hidden ref="file" accept=".png, .jpg" />
                </div>
              </div>
              <v-divider class="mb-4 mt-0"></v-divider>
              <div class="d-flex align-center flex-wrap images">
                <div
                  v-for="item in 6"
                  :key="`image-${item}`"
                  class="image"
                  :style="`background-image: url('` + urlHotGirl + `')`"
                >
                  <v-btn
                    class="icon-remove-image"
                    x-small
                    icon
                    @click="closeModal()"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
            <v-alert class="mt-4 yellow lighten-4 text-subtitle-1" dense>
              Tài liệu có chữ ký của khách theo quy định của công ty. Tải form
              trả máy sửa chữa, bảo hành tại
              <a
                href="https://d28jzcg6y4v9j1.cloudfront.net/archived/phieu_tiep_nhan_bhsc_24_10_2024.docx"
                >"Đây"</a
              >
            </v-alert>
          </div>
        </div>
      </v-card>
      <div class="d-flex align-center justify-center">
        <v-btn
          class="rounded-lg mt-5 px-7"
          color="primary"
          depressed
          @click="createGuaranteeVoucher"
        >
          Xác nhận
        </v-btn>
      </div>
    </div>
  </tp-modal>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "repair"
    }
  },
  data() {
    return {
      serialNumber: null,
      urlHotGirl: "https://pbs.twimg.com/media/EfyoodPUYAMuAje.jpg"
    };
  },
  computed: {
    serialInfo() {
      return this.$store.getters["GUARANTEE_VOUCHER/serialInfo"];
    },
    outerSerial() {
      return this.$store.getters["GUARANTEE_VOUCHER/outerSerial"];
    },
    guaranteeVoucher() {
      return this.$store.getters["GUARANTEE_VOUCHER/guaranteeVoucher"];
    },
    guaranteeVoucherStatusRequest() {
      return this.$store.getters["GUARANTEE_VOUCHER/statusRequest"];
    },
    employees() {
      return this.$store.getters["EMPLOYEE/allEmployees"];
    },
    user() {
      return this.$store.getters["AUTHENTICATION/user"];
    }
  },
  methods: {
    async createGuaranteeVoucher() {
      // Set type
      if (this.type === "guarantee") {
        this.guaranteeVoucher.type = 1;
      } else {
        this.guaranteeVoucher.type = 2;
      }
      this.guaranteeVoucher.assigned_to_id = this.user.id;
      this.guaranteeVoucher.branch_id = this.user.branch_id;

      const objectSender = JSON.parse(JSON.stringify(this.guaranteeVoucher));
      let serials = [];

      await objectSender.options.forEach(option => {
        const arr = option.serials.map(item => {
          return {
            id: item.id,
            sold_price: option.price,
            unit_sold_price: item.option_price
          };
        });

        serials = serials.concat(arr);
      });

      objectSender.xuat_serial_ids = serials;
      objectSender.outer_serial_id = this.outerSerial
        ? this.outerSerial.id
        : null;
      // Request create
      await this.$store.dispatch(
        "GUARANTEE_VOUCHER/createGuaranteeVoucher",
        objectSender
      );
      // Alert and replace router
      if (
        this.guaranteeVoucherStatusRequest.value ===
        "success-createGuaranteeVoucher"
      ) {
        // Alert
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Thêm phiếu bảo hành thành công"
          }
        });
        // Replace router
        await this.$router.push({
          name: "guarantee_vouchers-detail",
          params: {
            guaranteeId: this.guaranteeVoucher.id
          },
          query: {
            type: this.type
          }
        });
      }

      if (
        this.guaranteeVoucherStatusRequest.value ===
        "error-createGuaranteeVoucher"
      ) {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Có lỗi xảy ra. Vui lòng kiểm tra lại sau!"
          }
        });
      }
    },
    closeModal() {
      this.$modal.hide({
        name: "modal-create-service-area"
      });
    },

    async getSerialInfoBySerialNumber() {
      if (!this.serialNumber || this.serialNumber === "") return;
      else {
        await this.$store.dispatch(
          "GUARANTEE_VOUCHER/getSerialInfoBySerialNumber",
          this.serialNumber
        );
        if (
          this.guaranteeVoucherStatusRequest &&
          this.guaranteeVoucherStatusRequest.value ===
            "error-getSerialInfoBySerialNumber"
        )
          this.$toast.show({
            name: "toast-action-alert",
            payload: {
              message:
                "Serial này còn hàng trong hệ thống, hoặc đang nằm trong phiếu bảo hành, sửa chữa khác."
            }
          });
        else if (
          this.guaranteeVoucherStatusRequest &&
          this.guaranteeVoucherStatusRequest.value ===
            "success-getSerialInfoBySerialNumber"
        ) {
          this.guaranteeVoucher.serial_id = this.serialInfo.id;
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.border-0 {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.v-card {
  box-shadow: none !important;
}
.images {
  .image {
    padding-top: 50px;
    width: 50px;
    margin-right: 0.25rem;
    margin-bottom: 0.25rem;
    object-fit: cover;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    &:hover .icon-remove-image {
      opacity: 1;
    }
    .icon-remove-image {
      opacity: 0;
      transition: all ease 0.2s;
      position: absolute;
      top: -5px;
      right: -5px;
      border-radius: 50%;
      cursor: pointer;
      z-index: 10;
      color: #868686;
      background: #ffffff4a;
      border: 1px solid #868686;
      a {
        color: inherit;
        text-decoration: none;
      }
    }
  }
}
</style>
